<template>
  <ReportIndex
    :endpoint="getEndpoint"
    :fstId="`vehicleReports_${getId}`"
    :qso="getEndpointOptions"
  />
</template>

<script>
import { useEndpoints } from '@/composables'
import ReportIndex from '@/composites/report/index/Index.vue'

export default {
  name: 'VehicleReports',

  components: {
    ReportIndex,
  },

  computed: {
    getId() {
      return this.$route.params.id
    },
    getEndpoint() {
      return useEndpoints.report.index()
    },
    getEndpointOptions() {
      return { append: `&bike=${this.$route.params.id}`, prepend: `?` }
    },
  },
}
</script>
